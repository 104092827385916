import { useState } from "react";
import { Accordion } from "@/components";
import { useTransferAccount } from "@/features/transfers/providers";
import { useGetFavoredData, useNavigate } from "@/hooks";
import { useGetBanks } from "@/services/banks";
import { ContactAccount } from "@/types";
import { BankAccountType } from "@/types/transaction";
import {
  canEditContacts,
  formatAccount,
  formatDocument,
  getAccountNumber,
  hasPermission,
  MASKED_INPUT_DEFAULT_PROPS,
  routes,
  URL_PARAMS,
} from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import {
  Avatar,
  Button,
  Card,
  RadioGroup,
  TextField,
} from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { withMask } from "use-mask-input";
import { SelectBankComboBox } from "../../components";
import { BankForm, Reducer } from "./helper";

interface AccountListProps {
  updateBankForm: React.Dispatch<Reducer>;
  bankForm: BankForm;
  isLoading: boolean;
}

type Account = { account: ContactAccount };

const PixCard = ({ account }: Account) => {
  const { data: bankList } = useGetBanks();

  if (account.TransferType !== "pix") return null;

  const getAccountBankData = (ispb: string) => {
    const accountBankData = bankList.find(
      (bank) => bank.ispb === ispb.padStart(8, "0"),
    );
    return accountBankData;
  };

  return account.PixKey ? (
    <span className="overflow-hidden text-ellipsis whitespace-nowrap font-base text-base text-neutral-darkest">
      Chave Pix: {account.PixKey}
    </span>
  ) : (
    <div className="flex flex-col">
      <span className="w-full text-left font-base text-base text-neutral-darkest">
        {getAccountBankData(account.ISPB)?.shortBankName}
      </span>
      <span className="w-full text-left font-base text-base text-neutral-darkest">
        Ag {account.Branch} • {account.AccountNumber}
      </span>
    </div>
  );
};

export const AccountList = ({
  updateBankForm,
  bankForm,
  isLoading,
}: AccountListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [addAccount, setAddAccount] = useState(false);
  const transferType = searchParams.get(URL_PARAMS.type);
  const favoredData = useGetFavoredData();
  const navigate = useNavigate();
  const { updateTransferAccount } = useTransferAccount();
  const { data: bankList } = useGetBanks();

  const pixKey = searchParams.get(URL_PARAMS.pixKey);

  const handleAddAccount = () => {
    setAddAccount(true);
    updateBankForm({ name: "addAccount", value: true });
  };

  const accountsList = favoredData?.ContactAccounts.filter(
    (account) => account.TransferType === transferType,
  );

  const handleRedirectPixByKey = ({ account }: Account) => {
    if (account.TransferType !== "pix") return;

    searchParams.set(URL_PARAMS.pixKey, account.PixKey);
    searchParams.set(URL_PARAMS.beneficiary, favoredData.Id);
    searchParams.set(URL_PARAMS.accountId, account.Id);
    searchParams.set(
      URL_PARAMS.isFavorite,
      JSON.stringify(favoredData.IsFavoredContact),
    );

    setSearchParams(searchParams);
  };

  const handleRedirectPix = ({ account }: Account) => {
    if (account.TransferType !== "pix") return;

    if (account.PixKey) return handleRedirectPixByKey({ account });

    const { accountDigit, accountNumber } = getAccountNumber(
      account.AccountNumber,
    );

    const accountBankData = bankList.find(
      (bank) => bank.ispb === account.ISPB.padStart(8, "0"),
    );

    updateTransferAccount({
      transferType: "pix",
      isFavorite: favoredData.IsFavoredContact,
      accountDigit,
      accountNumber,
      bankAccountType: account.BankAccountType || "CC",
      bankName: accountBankData.shortBankName,
      ISPB: Number(accountBankData.ispb),
      beneficiaryName: favoredData.ContactName,
      branch: account.Branch,
      pixKey: "",
      document: formatDocument(favoredData.Document),
      pixType: "account",
    });

    searchParams.set(URL_PARAMS.beneficiary, favoredData.Id);
    searchParams.set(URL_PARAMS.accountId, account.Id);
    searchParams.set(
      URL_PARAMS.isFavorite,
      JSON.stringify(favoredData.IsFavoredContact),
    );

    setSearchParams(searchParams);

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const handleSelectAccount = (account: ContactAccount) => {
    searchParams.set(URL_PARAMS.type, account.TransferType);

    if (account.TransferType === "pix") {
      handleRedirectPix({ account });
      return;
    }

    searchParams.set(URL_PARAMS.accountId, account.Id);
    return navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const isFetchingPixKey = (account: ContactAccount) => {
    return (
      account.TransferType === "pix" && isLoading && account.PixKey === pixKey
    );
  };

  const shouldBeAbleToAddAccount =
    transferType === "ted" && hasPermission(canEditContacts);

  return (
    <ul className="flex flex-col gap-2">
      {accountsList?.map((account) => (
        <li key={account.Id} className="flex">
          <button
            onClick={() => handleSelectAccount(account)}
            className="flex flex-1"
            disabled={isLoading}
          >
            <Card className="flex w-[0px] flex-1 items-center justify-between gap-2">
              <Avatar.Root variant="secondary" size="md" className="shrink-0">
                <Avatar.Slot name="GeralBank" />
              </Avatar.Root>

              {account.TransferType === "pix" ? (
                <PixCard account={account} />
              ) : (
                <div className="flex flex-col">
                  <span className="w-full text-left font-base text-base text-neutral-darkest">
                    {account.BankName}
                  </span>
                  <span className="w-full text-left font-base text-base text-neutral-darkest">
                    Ag {account.Branch} • {account.AccountNumber}-
                    {account.AccountDigit}
                  </span>
                </div>
              )}
              <Icon
                name={
                  isFetchingPixKey(account)
                    ? "ArrowRefreshLine"
                    : "ArrowArrowNoLineRight"
                }
                className={twMerge(
                  "ml-auto size-6 flex-shrink-0 fill-neutral-darkest",
                  isFetchingPixKey(account) && "animate-spin",
                )}
              />
            </Card>
          </button>
        </li>
      ))}
      {addAccount ? (
        <Accordion.Root type="single" defaultValue="newAccount">
          <Accordion.Item
            value="newAccount"
            className="rounded-sm border border-primary-main p-4"
          >
            <Accordion.Content className="flex flex-col gap-6 p-1">
              <div>
                <TextField.Label htmlFor="bank">Instituição</TextField.Label>
                <SelectBankComboBox
                  onBankSelect={(bank) =>
                    updateBankForm({ name: "bank", value: bank })
                  }
                />
              </div>
              <RadioGroup.Root
                defaultValue="CC"
                className="flex-row justify-between"
                onValueChange={(value: BankAccountType) =>
                  updateBankForm({ name: "accountType", value })
                }
              >
                <RadioGroup.Items className="!pl-0">
                  <RadioGroup.Item
                    value="CC"
                    id="CC"
                    className="radio-button-transfers-wildcard"
                  />
                  <RadioGroup.Label htmlFor="CC">Corrente</RadioGroup.Label>
                </RadioGroup.Items>
                <RadioGroup.Items className="!pl-0">
                  <RadioGroup.Item
                    value="SA"
                    id="SA"
                    className="radio-button-transfers-wildcard"
                  />
                  <RadioGroup.Label htmlFor="SA">Poupança</RadioGroup.Label>
                </RadioGroup.Items>
                <RadioGroup.Items className="!pl-0">
                  <RadioGroup.Item
                    value="PA"
                    id="PA"
                    className="radio-button-transfers-wildcard"
                  />
                  <RadioGroup.Label htmlFor="PA">Pagamento</RadioGroup.Label>
                </RadioGroup.Items>
              </RadioGroup.Root>
              <div className="flex gap-4">
                <div className="w-[25%]">
                  <TextField.Label htmlFor="bankBranch">
                    Agência
                  </TextField.Label>
                  <TextField.Root>
                    <TextField.Input
                      id="bankBranch"
                      placeholder="Digite"
                      ref={withMask("9999", MASKED_INPUT_DEFAULT_PROPS)}
                      value={bankForm.branch}
                      onChange={({ target }) =>
                        updateBankForm({ name: "branch", value: target.value })
                      }
                    />
                  </TextField.Root>
                </div>
                <div className="w-[75%]">
                  <TextField.Label htmlFor="account">Conta</TextField.Label>
                  <TextField.Root>
                    <TextField.Input
                      id="account"
                      placeholder="Digite"
                      value={bankForm.account}
                      onChange={({ target }) => {
                        const formattedValue = formatAccount(target.value);

                        updateBankForm({
                          name: "account",
                          value: formattedValue,
                        });
                      }}
                    />
                  </TextField.Root>
                </div>
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      ) : (
        <Button.Root
          onClick={handleAddAccount}
          variant="link"
          className={twMerge(!shouldBeAbleToAddAccount && "hidden")}
        >
          Nova conta bancária
        </Button.Root>
      )}
    </ul>
  );
};
