import { useEffect, useReducer, useState } from "react";
import { EditIcon } from "@/assets/EditIcon";
import { ScrollArea } from "@/components";
import { useTransferAccount } from "@/features/transfers/providers";
import { TransferAccount } from "@/features/transfers/providers/transfer-account/types";
import { useNavigate } from "@/hooks";
import { useCreateP2P } from "@/services/p2p";
import { useTransferPix } from "@/services/pix";
import { TransferPixRequest } from "@/services/pix/types";
import { useCreateTed } from "@/services/ted";
import { CreateTedResponse } from "@/services/ted/types";
import { CafToken } from "@/types";
import {
  formatCurrency,
  formatPixKey,
  formatToInputDate,
  getAvatarFallback,
  handleNormalizeDate,
  IdentityConfirmationDialog,
  isValidDate,
  routes,
  TODAY,
  TODAY_INPUT,
  TRANSFER_TED_FEE,
  unMask,
  URL_PARAMS,
  useAccountStore,
  useUserStore,
  validateIdentity,
} from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Avatar, Button, TextField, toast } from "@hyperlocal/vital2";
import { isSameDay } from "date-fns";
import IntlCurrencyInput from "react-intl-currency-input";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { TransferLayout } from "../../components";
import {
  currencyConfig,
  getFormInitialState,
  reducer,
  styles,
  TransferForm,
} from "./helper";
import { Outlet } from "./outlet";

type TransformPixPayload = {
  transferAccount: TransferAccount;
  transferForm: TransferForm;
  token: string;
  accountId: string;
  userId: string;
  vertical: string;
} & CafToken;

const transformPixRequestPayload = ({
  transferAccount,
  transferForm,
  token,
  accountId,
  userId,
  vertical,
  cafToken,
}: TransformPixPayload): TransferPixRequest => {
  if (transferAccount.transferType !== "pix") return;

  const isSameDate = isSameDay(TODAY, handleNormalizeDate(transferForm.date));

  const key = formatPixKey(transferAccount.pixKey);

  const defaultData = {
    cafToken,
    amount: transferForm.value,
    description: transferForm.message || "Transferência PIX",
    externalCodeAuthorization: uuidv4(),
    isFavoredAccount: transferAccount.isFavorite,
    token,
    accountId,
    userId,
    vertical,
    ...(!isSameDate && {
      schedulingDate: handleNormalizeDate(transferForm.date),
    }),
  };

  if (transferAccount.pixType === "pixKey")
    return {
      ...defaultData,
      transferType: "KEY",
      payee: {
        key,
      },
    };

  return {
    ...defaultData,
    transferType: "ACCOUNT",
    payee: {
      name: transferAccount.beneficiaryName,
      document: transferAccount.document
        ? unMask(transferAccount.document)
        : null,
      ispb: transferAccount.ISPB,
      bankAccountType: transferAccount.bankAccountType,
      bankAccountNumber: `${transferAccount.accountNumber}${transferAccount.accountDigit}`,
      bankBranchNumber: transferAccount.branch,
    },
  };
};
type TransferCredentials = {
  token: string;
} & CafToken;

export const ConfirmTransfer = () => {
  const { dd, dt } = styles();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { transferAccount } = useTransferAccount();

  const { currentAccountId } = useAccountStore();

  const {
    user: { userId, accounts },
  } = useUserStore();

  const transferValue = Number(searchParams.get(URL_PARAMS.value)) || 0;
  const transferType = searchParams.get(URL_PARAMS.type);
  const accountId = searchParams.get(URL_PARAMS.accountId) || "";
  const [cafToken, setCafToken] = useState<CafToken["cafToken"]>("");
  const [cafErrorModalOpen, setCafErrorModalOpen] = useState(false);
  const beneficiary = searchParams.get(URL_PARAMS.beneficiary);

  const [transferForm, updateTransferForm] = useReducer(
    reducer,
    getFormInitialState(transferValue),
  );

  const createTedMutation = useCreateTed();

  const createP2pMutation = useCreateP2P();

  const transferPixMutation = useTransferPix();

  const handleCreateTed = async ({ token, cafToken }: TransferCredentials) => {
    if (transferAccount.transferType !== "ted") return;

    const isSchedule = transferForm.date !== TODAY_INPUT;

    return createTedMutation.mutateAsync({
      amount: transferForm.value,
      bankAccountNumber: transferAccount.accountNumber,
      bankAccountDigit: transferAccount.accountDigit,
      bankBranch: transferAccount.branch,
      bankCode: transferAccount.bankCode.toString(),
      bankName: transferAccount.bankName,
      bankBranchDigit: null,
      beneficiaryDocument: transferAccount.beneficiaryDocument,
      beneficiaryName: transferAccount.beneficiaryName,
      bankAccountType: transferAccount.bankAccountType,
      token,
      isFavoredAccount: transferAccount.isFavorite,
      externalCodeAuthorization: uuidv4(),
      description: transferForm.message || "Transferência Ted",
      ...(isSchedule && {
        schedulingDate: transferForm.date,
      }),
      cafToken,
    });
  };

  const handleCreateP2P = async ({ token, cafToken }: TransferCredentials) => {
    if (transferAccount.transferType !== "p2p") return;
    return createP2pMutation.mutateAsync({
      amount: transferForm.value,
      description: transferForm.message || "Transferência P2P",
      isFavoredAccount: transferAccount.isFavorite,
      bankBranch: transferAccount.branch,
      bankAccountNumber: transferAccount.accountNumber,
      bankAccountDigit: transferAccount.accountDigit,
      externalCodeAuthorization: uuidv4(),
      token,
      cafToken,
    });
  };

  const handleTransferPix = async ({
    token,
    cafToken,
  }: TransferCredentials) => {
    if (transferAccount.transferType !== "pix") return;

    const vertical =
      accounts.find((account) => account.accountId === currentAccountId)
        .vertical || "Banking";

    const response = await transferPixMutation.mutateAsync(
      transformPixRequestPayload({
        transferAccount,
        transferForm,
        accountId: currentAccountId,
        token,
        userId,
        vertical,
        cafToken,
      }),
    );

    return response;
  };

  const handleTransfer = async (token: string): Promise<CreateTedResponse> => {
    const transferMap = {
      ted: async () => await handleCreateTed({ token, cafToken }),
      pix: async () => await handleTransferPix({ token, cafToken }),
      p2p: async () => await handleCreateP2P({ token, cafToken }),
    };

    return transferMap[transferType]();
  };

  const handleNavigateMFA = async () => {
    await validateIdentity({
      onSuccess: (response) => {
        setCafToken(response.attestation);
        navigate({
          pathname: routes.transferToken,
          search: searchParams.toString(),
        });
      },
      onFailure: (errorMessage) => {
        if (errorMessage === "Resource not found") {
          setCafErrorModalOpen(true);
        } else {
          toast({
            title: "Erro",
            description: errorMessage,
            variant: "error",
            position: "top-right",
          });
        }
      },
    });
  };

  const handleBack = () => {
    setSearchParams();
    navigate(routes.transfers);
  };

  const handleEdit = () => {
    searchParams.delete(URL_PARAMS.pixKey);

    navigate({
      pathname: routes.transfersEditContact,
      search: searchParams.toString(),
    });
  };

  const handleClearValue = () =>
    updateTransferForm({ name: "value", value: 0 });

  const isTransferring =
    createTedMutation.isPending || createP2pMutation.isPending;

  const shouldEdit = !!beneficiary;

  const canSchedule = transferType !== "p2p";

  const shouldChargeFee = transferType === "ted";

  const getTransferFee = () => {
    if (transferType === "ted")
      return `TED: ${formatCurrency(TRANSFER_TED_FEE)}`;
  };

  useEffect(() => {
    const shouldRedirectToHome =
      (!transferAccount && !accountId) ||
      (transferType === "pix" && !transferAccount);

    if (shouldRedirectToHome) navigate(routes.transfers);
  }, [accountId, navigate, transferAccount, transferType]);

  return (
    <TransferLayout.Root>
      <ScrollArea className="h-full w-full px-5">
        <div className="relative mx-auto flex flex-col items-center justify-center px-1">
          <button
            className="absolute left-1 top-2 mobile:!hidden"
            onClick={handleBack}
          >
            <Icon
              name="ArrowNoLineLeft"
              fill="currentColor"
              className="size-4"
            />
          </button>
          <label className="h-[16px] font-base text-xs/4 font-medium text-neutral-darkest">
            Valor da Transferência
          </label>
          <div className="flex max-w-[283px] items-center justify-between border-b border-neutral-light">
            <span>R$</span>
            <IntlCurrencyInput
              className="w-full border-none px-1 text-center font-base text-lg/sm font-bold text-primary-main outline-none"
              config={currencyConfig}
              currency="BRL"
              value={transferForm.value}
              onChange={(_, value) =>
                updateTransferForm({ name: "value", value })
              }
              disabled={!!transferValue}
            />
            {!transferValue && (
              <button onClick={handleClearValue}>
                <Icon
                  name="SettingTrashClose"
                  className="size-4 fill-neutral-darkest"
                />
              </button>
            )}
          </div>
        </div>
        <dl className="mt-2 grid grid-cols-[min-content_1fr] gap-y-2 px-1 mobile:!hidden">
          <div className="col-span-2 mb-2 flex justify-between">
            <h5 className="font-base text-sm font-bold text-neutral-darkest">
              Transferir para favorecido
            </h5>

            {shouldEdit && (
              <button onClick={handleEdit}>
                <EditIcon />
              </button>
            )}
          </div>
          <dt className={dt()}>Destinatário</dt>
          <dd className={dd()} title={transferAccount?.beneficiaryName}>
            {transferAccount?.beneficiaryName}
          </dd>
          <dt className={dt()}>Instituição</dt>
          <dd className={dd()}>
            {transferAccount?.transferType !== "pix" &&
              `${transferAccount?.bankCode} -`}{" "}
            {transferAccount?.bankName}
          </dd>
          <dt className={dt()}>Agência</dt>
          <dd className={dd()}>{transferAccount?.branch}</dd>
          <dt className={dt()}>Conta</dt>
          <dd className={dd()}>
            {transferAccount?.accountNumber}-{transferAccount?.accountDigit}
          </dd>
          {transferAccount?.transferType === "pix" && (
            <>
              <dt className={dt()}>Documento</dt>
              <dd className={dd()}>{transferAccount?.document}</dd>
            </>
          )}
        </dl>
        <div className="mt-6 flex w-full items-center gap-2 px-1 desktop:!hidden">
          <Avatar.Root size="md" variant="primary" className="shrink-0">
            <Avatar.Fallback>
              {getAvatarFallback(transferAccount?.beneficiaryName)}
            </Avatar.Fallback>
          </Avatar.Root>
          <div className="flex w-full flex-col justify-between">
            <span className="font-base text-x4s/md font-medium text-neutral-darkest">
              Para
            </span>
            <span className="font-base text-base font-bold text-neutral-darkest">
              {transferAccount?.beneficiaryName}
            </span>
            <span className="font-base text-x3s/md font-medium text-neutral-dark">
              {transferAccount?.bankName}
            </span>
            <span className="font-base text-x3s/md font-medium text-neutral-dark">
              AG {transferAccount?.branch} • CC {transferAccount?.accountNumber}
              -{transferAccount?.accountDigit}
            </span>
            {transferAccount?.transferType === "pix" && (
              <span className="font-base text-x3s/md font-medium text-neutral-dark">
                {transferAccount?.document}
              </span>
            )}
          </div>
          {shouldEdit && (
            <button onClick={handleEdit}>
              <EditIcon />
            </button>
          )}
        </div>
        <div className="mt-4 grid grid-cols-2 gap-y-6 px-1">
          <div className="mobile:!col-span-2">
            <TextField.Label htmlFor="date">Data</TextField.Label>
            <TextField.Root>
              <TextField.Input
                type="date"
                id="date"
                value={transferForm.date}
                min={TODAY_INPUT}
                disabled={!canSchedule}
                onChange={({ target }) => {
                  const date = handleNormalizeDate(new Date(target.value));
                  if (!isValidDate(date)) return;
                  updateTransferForm({
                    name: "date",
                    value: formatToInputDate(date),
                  });
                }}
              />
              {canSchedule && (
                <TextField.Slot
                  name="GeralCalendar"
                  className={"cursor-pointer"}
                />
              )}
            </TextField.Root>
          </div>
          <div className="col-span-2 mb-6">
            <TextField.Label htmlFor="transferMessage">
              Mensagem da transferência
            </TextField.Label>
            <TextField.Root>
              <TextField.Input
                id="transferMessage"
                placeholder="Informe seu favorecido (Opcional)"
                value={transferForm.message}
                onChange={({ target }) =>
                  updateTransferForm({ name: "message", value: target.value })
                }
              />
            </TextField.Root>
          </div>
        </div>
      </ScrollArea>
      <TransferLayout.Footer className="flex-col">
        {shouldChargeFee && (
          <span className="text-xs/5 text-neutral-dark">
            Tarifa transferência {getTransferFee()}
          </span>
        )}
        <Button.Root
          fullWidth
          onClick={handleNavigateMFA}
          disabled={!transferForm.value}
        >
          Transferir
        </Button.Root>
      </TransferLayout.Footer>
      <Outlet
        handleTransfer={handleTransfer}
        isLoading={isTransferring}
        transferType={transferType}
        transferData={{
          ...transferForm,
          ...transferAccount,
        }}
      />
      <IdentityConfirmationDialog
        isOpen={cafErrorModalOpen}
        onClose={setCafErrorModalOpen}
      />
    </TransferLayout.Root>
  );
};
