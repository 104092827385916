import { MutationConfig, queryClient } from "@/lib";
import { TransferErrorResponse } from "@/types";
import {
  API,
  globalQueryClient,
  queries,
  transfersKeys,
  useAccountStore,
  useUserStore,
} from "@/utils";
import { useMutation } from "@tanstack/react-query";
import { CreateP2pRequest } from "./types";

const { balanceKeys, contactKeys } = queries;

const createP2P = async ({ cafToken, ...payload }: CreateP2pRequest) => {
  const response = await API.p2p.post("/api/v1/p2p", payload, {
    headers: {
      cafToken,
    },
  });

  return response.data;
};

type UseCreateP2PVariables = Omit<
  CreateP2pRequest,
  "userId" | "accountId" | "vertical"
>;

export const useCreateP2P = (
  config?: MutationConfig<typeof createP2P, TransferErrorResponse>,
) => {
  const { currentAccountId: accountId } = useAccountStore();

  const {
    user: { userId, accounts },
  } = useUserStore();

  const vertical =
    accounts.find((account) => account.accountId === accountId).vertical ||
    "Banking";

  return useMutation({
    mutationKey: ["createP2P"],
    mutationFn: (variables: UseCreateP2PVariables) =>
      createP2P({
        accountId,
        userId,
        vertical,
        ...variables,
      }),
    onSuccess: () => {
      globalQueryClient.invalidateQueries({
        queryKey: balanceKeys.getBalance({ accountId }),
      });
      queryClient.invalidateQueries({
        queryKey: transfersKeys.getTransactions(accountId),
      });
      queryClient.invalidateQueries({
        queryKey: contactKeys.getContactList({ accountId }),
      });
    },
    ...config,
  });
};
