import {
  NavigateOptions,
  To,
  useNavigate as useRouterNavigate,
} from "react-router-dom";

type WebTransitionProps = () => void;

let isTransitioning = false;

const handleWebTransition = (callback?: WebTransitionProps) => {
  if (!document?.startViewTransition) {
    return callback();
  }

  if (isTransitioning) {
    return;
  }

  isTransitioning = true;

  document.startViewTransition(() => {
    callback?.();
    isTransitioning = false;
  });
};

export const useNavigate = () => {
  const navigate = useRouterNavigate();

  const navigateCallback = (to: To | -1, options?: NavigateOptions) => {
    if (typeof to === "number") {
      handleWebTransition(() => navigate(-1));
    } else {
      return handleWebTransition(() => navigate(to, options));
    }
  };

  return navigateCallback;
};
