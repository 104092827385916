import {
  AccessGroupName,
  API,
  debuggingService,
  getTransfersRoutesData,
  getVertical,
  globalQueryClient,
  hasPermission,
  IdentityConfirmationDialog,
  ProtectedComponent,
  queries,
  setHeader,
  themeProvider,
  useAccountStore,
  useDeviceFingerprint,
  useUserStore,
  validateIdentity,
  Vertical,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

const routes = getTransfersRoutesData();

export {
  routes,
  setHeader,
  API,
  queries,
  globalQueryClient,
  useAccountStore,
  useUserStore,
  themeProvider,
  getVertical,
  Vertical,
  ProtectedComponent,
  hasPermission,
  AccessGroupName,
  validateIdentity,
  IdentityConfirmationDialog,
  debuggingService,
  useDeviceFingerprint,
};
