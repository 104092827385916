import { useState } from "react";
import { toast } from "@hyperlocal/vital2";
import { OutletContext } from "@/types";
import { MASKED_INPUT_DEFAULT_PROPS, routes } from "@/utils";
import { Button, TextField } from "@hyperlocal/vital2";
import { useNavigate, useOutletContext } from "react-router-dom";
import { withMask } from "use-mask-input";

const CODE_LENGTH = 6;

export const MFAValidation = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const { handleTransfer, transferType } = useOutletContext<OutletContext>();

  const handleConfirm = async () => {
    try {
      const { transactionCode, externalTransactionId, status } =
        await handleTransfer(code);

      const receiptId =
        transferType.toLowerCase() === "ted"
          ? externalTransactionId
          : transactionCode;

      const search =
        status === "WAITING_FOR_APPROVAL"
          ? ""
          : `receiptId=${receiptId}&receiptType=${transferType}&movement=OUT`;

      return navigate({
        pathname: routes.transferReceipt,
        search,
      });
    } catch (error) {
      const errorBody = error?.response?.data?.errors;
      const errorMessage = errorBody?.length
        ? errorBody[0].friendlyMessage
        : "Houve um erro ao realizar a transferência.";

      toast({
        title: "Erro",
        description: errorMessage,
        variant: "error",
      });
    }
  };

  return (
    <div className="flex h-full flex-col p-6">
      <h5 className="mb-4 font-base text-sm/md font-bold text-neutral-darkest">
        Informe o código de autenticação
      </h5>
      <div className="flex gap-2">
        <span>●</span>
        <span>
          Abra seu aplicativo Google Authenticator e insira o código de seis
          dígitos exibido no aplicativo.
        </span>
      </div>
      <TextField.Label htmlFor="totp" className="mt-inset-md">
        Digite o código
      </TextField.Label>
      <TextField.Root>
        <TextField.Input
          id="totp"
          type="text"
          ref={withMask("999999", MASKED_INPUT_DEFAULT_PROPS)}
          value={code}
          onChange={({ target }) => setCode(target.value)}
        />
      </TextField.Root>
      <Button.Root
        className="mt-auto"
        disabled={code.length !== CODE_LENGTH}
        onClick={handleConfirm}
      >
        Verificar
      </Button.Root>
    </div>
  );
};
